<template lang="html">

  <section class="view-entry-new-event" v-if="entrySelected">
    <bannerHead  dataTitle="Comunidad UFV"></bannerHead>

    <breacrumbs  dataParent="Comunidad UFV"  dataParentLink="/comunidad-ufv" :dataTitle="entrySelected.name" ></breacrumbs>

    <section class="content container">
      <p class="title">{{entrySelected.name}}</p>

      <p class="date" v-if="entrySelected.type==0">{{currentDateTime(entrySelected.publish_date)}}</p>
      <p class="date" v-else>{{currentDateTime(entrySelected.start_date)}}</p>

      <div :class="[entrySelected.type == 1 ? 'event-entry div-img' : 'div-img']">
        <img :src="entrySelected.image.url" :class="[entrySelected.type == 1 ? 'img-event' : '']" :alt="entrySelected.name">
        <div class="info-event" v-if="entrySelected.type == 1">
          <div class="container">
            <p class="title-line -white">Información del evento</p>
            <div class="grid-3">
              <div class="grid">
                <p class="title ">Fecha</p>
                <p class="date"><span>{{currentDateDay(entrySelected.start_date)}}</span>
                  {{currentDateMonth(entrySelected.start_date)}}</p>
              </div>
              <div class="grid" v-if="entrySelected.duration">
                <p class="title ">Horario</p>
                <p class="hour">{{entrySelected.duration}}</p>
              </div>
              <div class="grid" v-if="entrySelected.place">
                <p class="title ">Lugar</p>
                <p class="location">{{entrySelected.place}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-editor-js" v-if="entrySelected.category.name">
        <div class="categories">
          <span class="uppercase">{{entrySelected.category.name}}</span>
        </div>

        <p class="header">
          {{entrySelected.header}}
        </p>
        <EditorJs :object="entrySelected.body" />
      </div>
      <!-- <div class="recomend-entries" v-if="Object.keys(relatedEntries).length != 0 ">
        <div class="container">
          <p class="title-line -small">Te puede interesar</p>
          <div class="grid-3">
            <div class="grid" v-for="(entry, key ) in relatedEntries" :key="key">
              <entryView :dataEntry="entry"></entryView>
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </section>

</template>

<script lang="js">
  import { contentStore } from '@/stores/contents'
  import bannerHead from '@/components/banner-head.vue'
  import breacrumbs from '@/components/breacrumbs.vue'
  //import entryView from '@/components/entry-view.vue'
  const store = contentStore();
  import EditorJs from "@/components/editorJs.vue"
  import moment from 'moment'
  export default {
    name: 'view-entry-new-event',
    props: ['slug'],
    components: {
      bannerHead,
      breacrumbs,
      EditorJs,
      //entryView

    },
    mounted() {
      this.getEntryBySlug();
      store.loadEntries(
        {
          slug: this.slug
        }
      )
    },
    setup() {
      return {
        loadEntry: store.loadEntry,
        getEntryByCategoryBySLug: store.getEntryByCategoryBySLug,
        store
      }
    },
    data() {
      return {
        entrySelected: null,
      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
      currentDateDay(date) {
        return moment(date).locale('es').format('D')
      },
      currentDateMonth(date) {
        return moment(date).locale('es').format('MMMM')
      },
      getEntryBySlug() {
        let t = this;
        store.loadEntry(
          {
            slug: this.slug,
          }
        ).then(function (result) {
          t.entrySelected = result
        });
      }
    },
    computed: {
      relatedEntries() {
        return this.getEntryByCategoryBySLug(this.entrySelected.category.slug, this.slug);
      }
    },
    watch: {
      slug() {
        this.getEntryBySlug();
      },
    }
  }


</script>

<style scoped lang="scss">
  .view-entry-new-event {}
</style>
<template>
  <div class="newsletter-body" v-if="json.blocks">
    <template v-for="(el, key) in json.blocks" :key="key">
      <div v-if="el" class="contenedor-texto">
        <!-- Paragraph -->
        <div v-if="el.type == 'paragraph'" class="paragraph">
          <p v-html="parserTarget(el.data.text)"></p>
        </div>

        <!-- Image -->
        <template v-else-if="el.type == 'image'">
          <div class="image-container">
            <img :src="el.data.file.url" class="img-fluid" :alt="el.data" />
            <span v-html="el.data.caption" class="pie"></span>
          </div>
        </template>

        <!-- Header -->
        <h4 :nivel="el.data.level" v-else-if="el.type == 'header'" class="destacado" v-html="el.data.text"></h4>

        <!-- Embed -->
        <div class="div-iframe" v-if="el.type == 'embed'" :data-service="el.data.service">
          <iframe :src="el.data.embed"></iframe>
        </div>
        <!-- File -->
        <div class="file" v-else-if="el.type == 'attaches'">
          <a download :href="el.data.file.url" class="btn-blue-border" rel="noopener" target="_blank"
            :title="el.data.title">
            Descargar {{ el.data.title }}</a>
        </div>

        <!-- Slider -->
        <agile :options="myOptions" ref="carousel" v-else-if="el.type == 'gallery'">
          <div class="slide" v-for="(img, key) in el.data.images" :key="key">
            <div class="img-content" :style="`background-image:url(` + img.file.url + `)`" :title="img.caption"></div>
            <!-- <img class="img-fluid" :src="img.file.url" :alt="img.caption" /> -->
            <span>{{ img.caption }}</span>
          </div>
        </agile>

        <!-- Prety Link -->
        <div class="preaty-link row-center" v-if="el.type == 'linkTool'">
          <div class="link row-start">
            <a :href="el.data.link" class="" rel="noopener" target="_blank" :title="el.data.title"></a>
            <img v-if="!!el.data.meta.image" :src="el.data.meta.image.url" :alt="el.data.caption" />
            <h2>{{ el.data.meta.title }}</h2>
            <p>{{ el.data.meta.description }}</p>
          </div>
        </div>

        <!-- List -->
        <section v-else-if="el.type == 'list' || el.type == 'nestedList'">
          <template v-if="el.data.style == 'ordered'">
            <ol>
              <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item"
                :listStyle="el.data.style" />
            </ol>
          </template>
          <template v-else>
            <ul>
              <listComponent v-for="(item, index) in Object.assign({}, el.data.items)" :key="index" :item="item"
                :listStyle="el.data.style" />
            </ul>
          </template>
        </section>

        <!-- Boton -->
        <div class="button" v-else-if="el.type == 'button'">
          <a target="_blank" :href="el.data.link" class="btn-blue-border" :title="el.data.text">{{
            el.data.text
            }}</a>
        </div>

        <!-- Tabla -->
        <div class="table-container row-center" v-else-if="el.type == 'table'">
          <table>
            <tr v-for="(td, tdkey) in el.data.content" :key="tdkey">
              <td :key="ekey" v-for="(e, ekey) in td" v-html="e"></td>
            </tr>
          </table>
        </div>

        <!-- Columnas -->
        <div class="columns row-center" v-else-if="el.type == 'columns'">
          <div class="column" v-for="(el, subkey) in el.data.itemContent" :key="subkey">
            <editorjs :object="el"></editorjs>
          </div>
        </div>

        <!-- Raw -->
        <div v-else class="raw">
          <div v-html="el.data.html"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { VueAgile } from "vue-agile";
  import listComponent from "@/components/list-component.vue";
  export default {
    name: "editorjs",
    props: ["object"],
    components: {
      listComponent,
      agile: VueAgile,
    },
    data: () => ({
      myOptions: {
        responsive: [
          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              slidesToShow: 3,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              navButtons: false,
              slidesToShow: 2,
              dots: true,
            },
          },
          {
            breakpoint: 0,
            settings: {
              navButtons: false,
              slidesToShow: 1,
              dots: true,
            },
          },
        ],
      },
    }),
    computed: {
      json() {
        if (typeof this.object === "string" || this.object instanceof String) {
          return JSON.parse(this.object);
        }
        return this.object;
      },
    },
    methods: {
      parserTarget: function (text) {
        return text.replaceAll('<a href=', '<a target="_blank" href=')
      }
    },
    filters: {},
  };
</script>
<style>
  ol {
    counter-reset: item;
  }

  ol li {
    display: block;
  }

  .button {
    margin-bottom: 20px;
  }

  ol li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }

  h4 {
    margin-bottom: 15px;
  }

  .slide {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .slide .img-content {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
  }

  .slide img {
    width: 100%;
  }

  .agile__nav-button.agile__nav-button--next {
    background: url(/img/leftInt.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    top: 100px;
    position: absolute;
    right: -40px;
    border: 0;
    width: 35px;
    height: 35px;
    font-size: 0;
    cursor: pointer;
    transform: rotate(180deg);
  }

  .agile__nav-button.agile__nav-button--prev {
    background: url(/img/leftInt.png);
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    top: 100px;
    position: absolute;
    left: -40px;
    border: 0;
    width: 35px;
    height: 35px;
    font-size: 0;
    cursor: pointer;
  }

  .agile__dot--current.agile__dot,
  .agile__dot {
    list-style: none !important;
    padding: 10px 5px;
  }

  .agile__dot button {
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #487f3c5c;
    border: 0;
  }

  .agile__dot--current.agile__dot button {
    background: #487f3c;
  }
</style>